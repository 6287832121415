<template>
  <!-- 班组信息 --- 收费员 -->
  <div class="cashier_wrapper">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="cashier_box">
          <div
            v-for="(item, index) in list"
            :key="index"
            class="cashier_item"
            @click="$router.push({ name: 'SelfAttendance', query: item })"
          >
            <div class="item_pic">
              <img
                :src="
                  item.avatar
                    ? item.avatar
                    : require('../../assets/images/yonghu.png')
                "
                alt=""
              />
            </div>
            <div class="item_content">
              <div class="item_user">
                <span class="user_name">{{ item.name }}</span>
                <span class="user_phone">{{ item.phone }}</span>
              </div>
              <div class="item_account">收费账号：{{ item.account }}</div>
            </div>
            <div class="item_icon">
              <span class="icon-right"></span>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      refreshing: false,

      taotal: 0,
      pageNum: 1,
      pageSize: 10
    };
  },
  methods: {
    onLoad() {
      // this.$get(this.$config.TL_TEAM_INFO_GET_TOLL_MAN_TEAM_INFO_LIST, {
      //   pageNum: this.pageNum,
      //   pageSize: this.pageSize,
      // })
      this.$api
        .getTollmanTeamInfoList({
          pageNum: this.pageNum,
          pageSize: this.pageSize
        })
        .then(res => {
          if (this.refreshing) {
            this.list = [];
            this.refreshing = false;
          }

          res.data.records.forEach(item => {
            this.list.push(item);
          });

          this.loading = true;

          this.pageNum++;

          this.total = res.data.total;

          if (this.list.length >= this.total) {
            this.finished = true;
          }
        })
        .catch(() => {
          this.refreshing = false;
          this.loading = false;
          this.finished = true;
        });
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      this.pageNum = 1;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    }
  }
};
</script>

<style lang="less" scoped>
.cashier_wrapper {
  width: 100%;
}

.cashier_box {
  padding: 10px;
  background-color: #edf0f7;
}

.cashier_item {
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  margin-bottom: 10px;

  .item_pic {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: #efefef;
    margin-right: 20px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .item_content {
    flex: 1;
    font-size: 14px;
    color: #333;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .item_user {
      display: flex;
      align-items: center;

      .user_name {
        flex: 0 0 80px;
        font-size: 16px;
        font-weight: 600;
        color: #000;
      }
    }
  }

  .item_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #b6b6b6;
  }
}
</style>

<template>
  <!-- 班组信息外壳 -->
  <div class="team_wrapper">
    <div class="titleBar" v-if="isApp"></div>

    <div class="pageTitle">
      <div class="title_icon" @click="$router.go(-1)">
        <span class="icon-left"></span>
      </div>
      <div class="title_txt">
        <span>班组信息</span>
      </div>
    </div>

    <div class="team_BScroll" ref="teamBScroll">
      <div class="team_box">
        <keep-alive>
          <jobs v-if="pageName == 'Jobs'"></jobs>
          <cashier v-if="pageName == 'Cashier'"></cashier>
        </keep-alive>
      </div>
    </div>

    <div class="team_nav_box"></div>
    <div class="team_nav">
      <div class="nav_item">
        <div
          class="item_box"
          @click="pageName = 'Jobs'"
          :class="{ active: pageName == 'Jobs' }"
        >
          <span class="item_txt">收费岗</span>
        </div>
      </div>
      <div class="nav_item">
        <div
          class="item_box"
          @click="pageName = 'Cashier'"
          :class="{ active: pageName == 'Cashier' }"
        >
          <span class="item_txt">收费员</span>
        </div>
      </div>
    </div>

    <router-view></router-view>
  </div>
</template>

<script>
import jobs from "./Team/Jobs";
import cashier from "./Team/Cashier";
import { mapState } from "vuex";
export default {
  components: {
    jobs,
    cashier
  },
  data: () => ({
    pageName: "Jobs"
  }),
  computed: {
    ...mapState({
      isApp: state => state.isApp
    })
  },
  created() {
    this.$tools.entering();
  }
};
</script>

<style lang="less" scoped>
.team_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #edf0f7;
  display: flex;
  flex-direction: column;
}

.team_BScroll {
  flex: 1;
  width: 104%;
  overflow: scroll;
  padding: 0 2%;
  transform: translateX(-2%);

  .team_box {
    min-height: 101%;
  }
}

.team_nav_box {
  height: 50px;
}

.team_nav {
  // flex: 0 0 50px;
  height: 50px;
  // height: 7.5%;
  width: 100%;
  position: absolute;
  bottom: 0;
  // margin-top: 8px;
  background-color: #fff;
  display: flex;
  border-top: 1px solid rgba(7, 17, 27, 0.1);

  .nav_item {
    flex: 1;
    font-size: 16px;
    font-weight: 600;

    .item_box {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;

      &.active .item_txt {
        color: #238be4;
      }
    }

    .item_txt {
      display: block;
      width: 100%;
      text-align: center;
      border-right: 1px solid rgba(7, 17, 27, 0.1);
      color: #adb6bd;
    }

    &:last-child .item_txt {
      border: 0;
    }
  }
}
</style>

<template>
  <!-- 班组信息页面 -->

  <div class="jobs_wrapper">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="jobs_box">
          <div
            class="jobs_item"
            @click="
              $router.push({
                name: 'Attendance',
                query: {
                  stationId: v.stationId,
                  stationName: v.stationName
                }
              })
            "
            v-for="(v, i) in list"
            :key="i"
          >
            <div class="item_title">{{ v.stationName }}</div>
            <div class="item_address">
              <i>
                <img
                  class="auto_img"
                  src="../../assets/images/地址.png"
                  alt=""
                /> </i
              >地 址：{{ v.location }}
            </div>

            <div class="item_cashier">
              <i
                ><img
                  class="auto_img"
                  src="../../assets/images/皇冠.png"
                  alt=""/></i
              >当班收费员：{{ v.employeeName || "无" }}
            </div>
            <div class="item_phone">
              <i
                ><img
                  class="auto_img"
                  src="../../assets/images/电话.png"
                  alt=""/></i
              >联系电话：{{ v.phone || "无" }}
            </div>

            <div class="item_btn_box">
              <div class="item_btn car">
                <div class="btn_icon">
                  <img
                    class="auto_img"
                    src="../../assets/images/车流量.png"
                    alt=""
                  />
                </div>
                <div class="btn_txt">
                  全部车位：<span>{{ v.parkingSpaceNum }}</span>
                </div>
              </div>
              <div class="item_btn">
                <div class="btn_icon shifts">
                  <img
                    class="auto_img"
                    src="../../assets/images/日历.png"
                    alt=""
                  />
                </div>
                <div class="btn_txt">
                  班制：<span>{{ v.shifts }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      total: 0,

      pageNum: 1,
      pageSize: 8
    };
  },
  methods: {
    onLoad() {
      // this.$get(this.$config.TL_TEAM_INFO_GET_TEAM_INFO_LIST, {
      //   pageNum: this.pageNum,
      //   pageSize: this.pageSize,
      // })
      this.$api
        .getTeamInfoList({
          pageNum: this.pageNum,
          pageSize: this.pageSize
        })
        .then(res => {
          console.log("班组信息== > ", res);
          if (this.refreshing) {
            this.list = [];
            this.refreshing = false;
          }

          res.data.records.forEach(item => {
            this.list.push(item);
          });

          this.pageNum++;

          this.total = res.data.total;

          this.loading = false;

          if (this.list.length >= this.total) {
            this.finished = true;
          }
        })
        .catch(() => {
          this.refreshing = false;
          this.loading = false;
          this.finished = true;
        });
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      this.pageNum = 1;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    }
  }
};
</script>

<style lang="less" scoped>
.jobs_wrapper {
  width: 100%;
}

.jobs_box {
  padding: 10px;
  background-color: #edf0f7;

  .jobs_item {
    padding: 10px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 2px 2px 4px #ccc;
    margin-bottom: 10px;

    &:last-child {
      margin: 0;
    }

    .item_title {
      padding-left: 12px;
      color: #000;
      font-weight: 700;
      font-size: 18px;
      margin-bottom: 10px;
    }

    > div {
      font-size: 14px;
      margin-bottom: 8px;
      color: #333;
      display: flex;
      align-items: center;

      i {
        display: inline-block;
        height: 20px;
        width: 20px;
        margin-right: 4px;
      }
    }

    .item_btn_box {
      display: flex;
      justify-content: space-around;

      .item_btn {
        padding: 8px;
        display: flex;
        align-items: center;
        border: 1px solid #eef0f2;

        &.car {
          .btn_icon {
            background-color: #fa5252;
          }

          .btn_txt span {
            font-size: 18px;
            color: #333;
          }
        }

        .shifts {
          background-color: #82c91e;
        }

        .btn_icon {
          width: 30px;
          height: 30px;
          margin-right: 6px;
          border-radius: 50%;
          padding: 6px;
        }

        .btn_txt {
          font-size: 12px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
</style>
